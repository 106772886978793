.home-screen {
  max-width: 800px;
  padding: 24px 24px 0;
  margin: 0 auto;
}

.home-screen > section {
  padding-bottom: 24px;
  
  li {
    padding-bottom: 2px;
  }
}

@media(min-width: 800px) {
  .home-screen {
    padding-top: 120px;
  }
}